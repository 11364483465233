import './styles/app.scss';

document.addEventListener("DOMContentLoaded", () => {
  const excludeCountries = ["af", "dz", "bd", "by", "bw", "bn", "kh", "cg", "cd", "et", "in", "ir", "iq", "ke", "kw", "lb", "ly", "mn", "mz", "mm", "na", "np", "ng", "om", "ps", "ph", "ru", "rw", "so", "ss", "sd", "sy", "tz", "ug", "uy", "zm"]
  const input = document.querySelector("#phone");
  const header = document.querySelector(".header");
  const btnMenuMobile = document.querySelector('.header__contact.mobile')
  const iconMenu = btnMenuMobile.querySelector('#menu-icon')
  const crossMenu = btnMenuMobile.querySelector('#cross-menu')
  const menuMobile = document.querySelector('.menu-mobile')
  const linkMobileListener = document.querySelectorAll('.link_mobile_listener')

  const onToggleModal = () => {
    iconMenu.classList.toggle('hidden')
    crossMenu.classList.toggle('hidden')
    menuMobile.classList.toggle('hidden')
    menuMobile.classList.toggle('opacity')
    btnMenuMobile.classList.toggle('active')
    header.classList.toggle('active')
    document.querySelector('body').classList.toggle('scroll-hidden')
  }

  btnMenuMobile.addEventListener('click', () => {
    onToggleModal()
  })

  linkMobileListener.forEach(item => {
    item.addEventListener('click', () => {
      onToggleModal()
    })
  })

  const emblaNode = document.querySelector('.developers-grid')
  const options = { loop: false, align: 'center'  }
  const embla = EmblaCarousel(emblaNode, options)
  const prevBtn = document.querySelector('.prevBtn')
  const nextBtn = document.querySelector('.nextBtn')
  prevBtn.addEventListener('click', embla.scrollPrev, false);
  nextBtn.addEventListener('click', embla.scrollNext, false);

  const form = document.getElementById("contact-form");

  const int = window.intlTelInput(input, {
    initialCountry: "ua",
    separateDialCode: true,
    excludeCountries: excludeCountries,
    utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
  });

  document.querySelector('.iti__flag-container').addEventListener('click', () => {
    excludeCountries.forEach(item => {
      const target = document.querySelector(`[data-country-code=${item}]`)
      target?.remove()
    })
  })

  excludeCountries.forEach(item => {
    const target = document.querySelector(`[data-country-code=${item}]`)
    target?.remove()
  })

  form.addEventListener("submit", async (event) => {
    event.preventDefault()
    const formData = new FormData(form)

    const object = {};
    formData.forEach(function(value, key){
      object[key] = value;
    });

    object.phone = int.getNumber()

    const response = await fetch(`${window.location.origin}/api/mail/send`, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      method: "POST",
      body: JSON.stringify(object)
    })

    if(!response.ok) {
      alert('Error send form')
    } else {
      form.reset()
      alert('Success')
    }
  });

});
